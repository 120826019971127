import React from "react"
import Loadable from "@loadable/component"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Anchor from "@components/content/anchor.jsx"
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import DoctorDiscussionGuideCTA from "@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx"
const BloodVialIcon = Loadable(() => import('@components/content/icons/BloodVial.jsx'))
const CalendarSmallIcon = Loadable(() => import('@components/content/icons/CalendarSmall.jsx'))
const PillGlowIcon = Loadable(() => import('@components/content/icons/PillGlow.jsx'))
const StethoscopeSmallIcon = Loadable(() => import('@components/content/icons/StethoscopeSmall.jsx'))
const SunMoonIcon = Loadable(() => import('@components/content/icons/SunMoon.jsx'))
const UrineTestIcon = Loadable(() => import('@components/content/icons/UrineTest.jsx'))
const Video = Loadable(() => import('@components/content/video/Video.jsx'))

export const Head = () => (
	<>
		<script type='application/ld+json'
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "How LUPKYNIS® (voclosporin) is Helping People",
					"description": "See how people are fighting back against lupus nephritis. Watch their stories to see how LUPKYNIS helps them do it.",
					"thumbnailUrl": "https://www.lupkynis.com/images/taking-lupkynis/watch-real-people-stories-poster-pt-2.png",
					"uploadDate": "2022-10-01",
					"embedUrl": "https://www.lupkynis.com/taking-lupkynis",
					"duration": "PT5M27S",
				})
			}}
		/>
	</>
)

const takingLupkynis = () => (
    <Layout addedClass="page--taking-lupkynis">
        <Seo title="Taking LUPKYNIS&reg; (voclosporin) | How to Take LUPKYNIS&reg; (voclosporin)" description="Talk to your doctor about what to expect when taking LUPKYNIS. Learn more about how to take LUPKYNIS, including dosing information and dosage schedule. See full Safety and Prescribing Information, and BOXED WARNING." canonicalURL="https://www.lupkynis.com/taking-lupkynis" />
        <Hero addedClass="bg-coral hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">Taking LUPKYNIS</h1>
                        <h2>What to <span>expect</span> when taking LUPKYNIS</h2>
                        <p><strong>When you take LUPKYNIS as prescribed by your doctor, it can help maintain your kidney function.</strong></p>
                        <p>While taking LUPKYNIS, routine visits with your doctor help them stay informed about your treatment progress and kidney function, and can also help them keep track of your symptoms.</p>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'taking-lupkynis/hero.png', mobileSrc: 'taking-lupkynis/hero--mobile.png', altText: 'Image of LUPKYNIS® (voclosporin) package'}} />
                    </div>
                </div>
            </div>
            <div className="icon-bar col-wrapper bg-light-green text-center">
                <div className="container">
                    <div className="icon-bar__item">
                        <div className="icon">
                            <UrineTestIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Urine tests (urinalysis)</strong> monitor protein levels (proteinuria) and blood (hematuria) in the urine. Proteinuria is one of the key signs of active lupus nephritis.</p>
                        </div>
                    </div>
                    <div className="icon-bar__item">
                        <div className="icon">
                            <BloodVialIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Blood tests</strong> are used to check how well your kidneys are working</p>
                        </div>
                    </div>
                    <div className="icon-bar__item">
                        <div className="icon">
                            <StethoscopeSmallIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Routine visits</strong> ensure you're still on track with your treatment</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-block text-center">
                <p>With Aurinia Alliance<sup>&reg;</sup>, your personalized support program, you'll be connected with a dedicated Nurse Case Manager who will be here with you every step of the way on your LUPKYNIS treatment journey. It's easy to enroll&mdash;<Anchor url="https://www.auriniaalliance.com" target="_blank">click here</Anchor> to learn more about the one&#8209;on&#8209;one support you'll receive and get started today.</p>
            </div>
        </Hero>
        <section id="WatchVideo" className="col-wrapper bg-white">
            <div className="container">
                <div className="copy-block">
                    <h2>See how LUPKYNIS is helping people </h2>
                    <p>Malisha, Suriya and Jennifer are fighting back against lupus nephritis &mdash; watch their stories to see how LUPKYNIS helps them do it. </p>
                </div>
                <Video src="/videos/the-common-thread-of-lupus-nephritis-part2.mp4" type="video/mp4" poster="/images/taking-lupkynis/watch-real-people-stories-poster-pt-2.png" title="See how LUPKYNIS is helping people" controlsList="nodownload" />
            </div>
        </section>
        <section id="HowToTakeLupkynis" className="bg-light-pink text-center">
            <div className="col-wrapper">
                <div className="container how-to-top-section">
                    <div className="how-to-img">
                        <Image data={{desktopSrc: 'taking-lupkynis/howto.png', mobileSrc: 'taking-lupkynis/howto--mobile.png'}} />
                    </div>
                    <div className="copy-block">
                        <h2 className="heading heading--accent">How to take lupkynis</h2>
                        <ul className="circle-bullet-list text-left">
                            <li>LUPKYNIS is an oral medication for the treatment of adults  with active lupus nephritis.</li>
                            <li>LUPKYNIS must be swallowed whole on an empty stomach as close to 12&nbsp;hours apart as possible (with at least 8&nbsp;hours between doses)</li>
                            <li>If you miss a dose, take it as soon as possible within 4&nbsp;hours after missing the dose.</li>
                            <li>If it's been longer than 4&nbsp;hours, wait until the usual scheduled time to take your next regular dose&#8212;do not double your next dose.</li>
                        </ul>
                    </div>
                </div>
                <div className="copy-block">
                    <p>Before starting LUPKYNIS, talk to your doctor about all the medications you are currently taking. Your doctor will determine the right dosage for you when prescribing LUPKYNIS. They will monitor you throughout the course of your treatment and may also change your dose. Always take LUPKYNIS as directed by your doctor.</p>
                </div>
            </div>
            <div className="container">
                <div className="copy-block">
                    <h3 className="heading heading--subhead">A dosing schedule that fits into your life, not the other way around</h3>
                </div>
                <div className="icon-bar col-wrapper bg-pink">
                    <div className="container">
                        <div className="icon-bar__item">
                            <div className="icon">
                                <PillGlowIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>Taken by mouth,</strong> not by injection</p>
                            </div>
                        </div>
                        <div className="icon-bar__item">
                            <div className="icon">
                                <SunMoonIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>A standard dose</strong> is 3&nbsp;capsules, 2x a day</p>
                            </div>
                        </div>
                        <div className="icon-bar__item">
                            <div className="icon">
                                <CalendarSmallIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>Dosage schedule</strong> stays the same throughout treatment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="polaroid-sticky">
                <div className="container">
                    <Image data={{desktopSrc: 'taking-lupkynis/polaroid.png', mobileSrc: 'taking-lupkynis/polaroid--mobile.png', altText:'Image of Suriya, started treatment 2021'}} />
                    <div className="green-post ripped-tape">
                        <div className="post-inner">
                            <p className="double-quotes">I started taking <strong>3 capsules twice a day</strong> and often set alarms on my phone to help me remember to take them, keeping me on track. I usually take the capsules in the morning and after dinner with a glass of water and <strong>I am loving the results I am receiving.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Treatment" className="col-wrapper bg-purple">
            <div className="container">
                <div className="copy-block">
                    <h2 className="heading heading--accent">LUPKYNIS safety</h2>
                    <h3 className="heading heading--subhead">Feel informed about treatment</h3>
                    <p>LUPKYNIS was tested in clinical studies that involved more than 350&nbsp;people across different races and ethnicities.</p>
                    <p>LUPKYNIS can cause serious side effects, so it's important to talk to your doctor right away if you experience any of these <Anchor url="#ISI">signs or symptoms</Anchor>.</p>
                    <p><strong>The most common side effects of LUPKYNIS are diarrhea, headache, cough, urinary tract infection, stomach pain, heartburn, and loss of hair (alopecia). These are not all the possible side effects of LUPKYNIS. For more information, talk to your doctor.</strong></p>
                </div>
                <div>
                    <Image data={{desktopSrc: 'taking-lupkynis/group.png', mobileSrc: 'taking-lupkynis/group--mobile.png'}} />
                </div>
            </div>
        </section>
        <DoctorDiscussionGuideCTA />
        <section id="PeerConnect" className="col-wrapper bg-white">
            <div className="container">
                <div className="copy-block">
                    <h3 className="heading heading--subhead">Connect with someone who's been there</h3>
                    <p><strong>Lupus Nephritis Peer Connect<sup>TM</sup></strong> is a mentorship program that allows you to speak with someone who may understand what you're going through. Our peer mentors volunteer for one-on-one calls to answer your questions, offer encouragement, and share their own personal experiences with lupus nephritis and treatment with LUPKYNIS.</p>
                    <div>
                    <Anchor url="https://www.lnpeerconnect.com/" target="_blank" linkClass="button">Learn More</Anchor>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default takingLupkynis